/* global coreui */

/**
 * --------------------------------------------------------------------------
 * CoreUI Boostrap Admin Template (3.4.0): tooltips.js
 * License (https://coreui.io/pro/license)
 * --------------------------------------------------------------------------
 */

document.querySelectorAll('[data-toggle="tooltip"]').forEach(element => {
  // eslint-disable-next-line no-new
  new coreui.Tooltip(element, {
    offset: ({ placement, reference, popper }) => {
      console.log(placement)
      console.log(reference)
      console.log(popper)
      if (placement === 'bottom') {
        return [0, popper.height / 2];
      } else {
        return [];
      }
    }
  })
})
